import Vue from "vue";
import Vuex from "vuex";
import VueAxios from "vue-axios";
import axios from "axios";
Vue.use(Vuex);
Vue.use(VueAxios, axios);
const API_URL = process.env.VUE_APP_BACKEND;
export default new Vuex.Store({
  state: {
    currentOperation: null,
    WORKORDER: null,
    mop: false,
    paused: false,
    mop_finished: false,
    logout: false,
    active_wo: [],
    posting_wo: false,
    batch_days: [],
    batchedWorkOrders: [],
    NOWTime: null,
    started: null,
  },
  mutations: {
    setWorkOrder(state, data) {
      state.WORKORDER = data;
    },
    setMOP(state, data) {
      state.mop = data;
    },
    setPause(state, data) {
      state.mop = data;
    },
    setLogout(state, data) {
      state.logout = data;
    },
    setActiveWO(state, data) {
      state.active_wo = data;
    },
    setbatchedWorkOrders(state, data) {
      state.batchedWorkOrders = data;
    },
    setNow(state, data) {
      state.NOW = data;
    },
  },
  actions: {
    fetchWO({ commit }, data) {
      var login_id = localStorage.login_id;
      var work_center = localStorage.workstation;
      axios
        .get(`${API_URL}/workorder?badge_id=${data.badge_id}&workorder=${data.wo}&login_id=${login_id}&work_center=${work_center}`)
        .then((res) => {
          if (res.data.success) {
            commit("setWorkOrder", res.data.workorder);
          }
        })
        .catch((error) => {
          alert(JSON.stringify(error) + " CONTACT IT");
          location.reload();
        });
    },
    getWorkOrder({ commit }, data) {
      var login_id = localStorage.login_id;
      var work_center = localStorage.workstation_id;
      axios
        .get(`${API_URL}/workorder?badge_id=${data.badge_id}&workorder=${data.wo}&login_id=${login_id}&work_center=${work_center}`)
        .then((res) => {
          if (!res.data.success) {
            if (res.data.msg) {
              commit("setWorkOrder", res.data.msg);
            } else {
              commit("setWorkOrder", false);
            }
          } else {
            commit("setWorkOrder", res.data.workorder);
          }
        })
        .catch((error) => {
          alert(JSON.stringify(error) + " CONTACT IT");
          location.reload();
        });
    },
    startBatchedWorkOrder({ commit }, data) {
      var login_id = localStorage.login_id;
      var work_center = localStorage.workstation_id;
      var badge_id = localStorage.badge_id;
      return new Promise((resolve, reject) => {
        axios
          .get(`${API_URL}/workorder?badge_id=${badge_id}&workorder=${data.wo}&login_id=${login_id}&work_center=${work_center}&batch_id=${data.batch_id}`)
          .then((res) => {
            if (!res.data.success) {
              resolve(false);
            } else {
              resolve(true);
            }
          })
          .catch((error) => {
            throw new Error();
          });
      });
    },
    endBatchedWorkOrder({ commit }, data) {
      var login_id = localStorage.login_id;
      data.login_id = login_id;
      this.state.posting_wo = true;
      return new Promise((resolve, reject) => {
        axios
          .put(API_URL + "/mop", data, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            this.state.posting_wo = false;
            if (!res.data.success) {
              resolve(false);
            } else {
              resolve(true);
            }
          })
          .catch((error) => {
            throw new Error();
          });
      });

      // });
    },
    putWorkOrderMOP({ commit }, data) {
      var login_id = localStorage.login_id;
      data.login_id = login_id;
      this.state.posting_wo = true;
      axios
        .put(API_URL + "/mop", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.state.posting_wo = false;
          if (!res.data.success) {
            if (res.data.code == 216) {
              commit("setMOP", "routing_issue");
            }
          } else {
            commit("setMOP", true);
          }
        })
        .catch((error) => {
          alert("Error Completing work order. Please Contact Lead.");
          location.reload();
        });
      // });
    },
    pauseWorkOrderMOP({ commit }, data) {
      // return new Promise((resolve, reject) => {
      axios
        .put(API_URL + "/mop", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          commit("setPause", "qchold");
        })
        .catch((error) => {
          alert(JSON.stringify(error) + " CONTACT IT");
          location.reload();
        });
      // });
    },
    unpauseWorkOrderMOP({ commit }, data) {
      axios
        .put(API_URL + "/mop", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          commit("setPause", false);
        })
        .catch((error) => {
          alert(JSON.stringify(error) + " CONTACT IT");
          location.reload();
        });
    },
    logoutWorkOrderMOP({ commit }, data) {
      axios
        .put(API_URL + "/mop", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // commit("logout", false);
          // setTimeout(() => {
          //   commit("setLogout", false);
          // }, 0);
        })
        .catch((error) => {
          alert(JSON.stringify(error) + " CONTACT IT");
          location.reload();
        });
    },
    getInprocessWorkOrders({ commit }) {
      return new Promise((resolve, reject) => {
        var work_center = localStorage.workstation_id;
        axios
          .get(`${API_URL}/completed-work-orders?work_centers=${work_center}`)
          .then((res) => {
            resolve("done");
            // if (!res.data.success) {
            //   // if (res.data.msg) {
            //   //   commit("setWorkOrder", res.data.msg);
            //   // } else {
            //   //   commit("setWorkOrder", false);
            //   // }
            //   console.log("in if");
            // } else {
            //   console.log("in else");
            commit("setActiveWO", res.data.results);
            // }
          })
          .catch((error) => {
            alert(JSON.stringify(error) + " CONTACT IT");
            location.reload();
          });
      });
    },
    getStartEndBatchDays({ commit }, data) {
      this.state.posting_wo = true;
      return new Promise((resolve, reject) => {
        var type = data.type;
        var func = data.func;
        var work_center = localStorage.workstation_id;
        axios
          .get(`${API_URL}/released-workorders?work_centers=${work_center}&type=${type}&entry_time=${data.entry_time}`)
          .then((res) => {
            this.state.posting_wo = false;
            // resolve(res.data);
            if (res.data.success) {
              this.state.NOWTime = res.data.NOW;
              resolve(res.data.data);
            } else reject("Error Getting Orders. Please Contact Lead.");
            // commit("setActiveWO", res.data.results);
            // }
          })
          .catch((error) => {
            alert(JSON.stringify(error) + " CONTACT IT");
            reject("Network Error");
          });
      });
    },
    getBatchWorkOrders({ commit }, data) {
      this.state.posting_wo = true;
      return new Promise((resolve, reject) => {
        var type = data.type;
        var bussines_function = data.bussines_function;
        var work_center = localStorage.workstation_id;
        var login_id = localStorage.login_id;
        axios
          .get(`${API_URL}/get-batch-workorders?login_id=${login_id}&work_center=${work_center}&type=${type}&entry_time=${data.entry_time}&bussines_function=${bussines_function}`)
          .then((res) => {
            this.state.posting_wo = false;
            commit("setbatchedWorkOrders", res.data.results);
            if (res.data.success) resolve();
            else reject(res.data.err);
            // commit("setActiveWO", res.data.results);
            // }
          })
          .catch((error) => {
            alert(JSON.stringify(error) + " CONTACT IT");
            reject("Network Error");
          });
      });
    },
    batchendWorkorders({ commit }, data) {
      this.state.posting_wo = true;
      return new Promise((resolve, reject) => {
        axios
          .get(`${API_URL}/batchend-workorders?batch_id=${data.batch_id}`)
          .then((res) => {
            this.state.posting_wo = false;
            commit("setbatchedWorkOrders", res.data.results);
            if (res.data.success) resolve();
            else reject(res.data.err);
            // commit("setActiveWO", res.data.results);
            // }
          })
          .catch((error) => {
            alert(JSON.stringify(error) + " CONTACT IT");
            reject("Network Error");
          });
      });
    },
    getBatchId({ commit }, data) {
      return new Promise((resolve, reject) => {
        var bussines_function = data.bussines_function;
        var login_id = localStorage.login_id;
        var work_center = localStorage.workstation_id;
        axios
          .get(`${API_URL}/get-batch-workorders?login_id=${login_id}&type=createBatchRecord&entry_time=${data.entry_time}&bussines_function=${bussines_function}&work_center=${work_center}`)
          .then((res) => {
            this.state.posting_wo = false;
            if (res.data.success) {
              resolve(res.data.batchid);
            } else {
              reject("Network Error");
            }
          })
          .catch((error) => {
            alert(JSON.stringify(error) + " CONTACT IT");
            reject("Network Error");
          });
      });
    },
    closeBatchRecord({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${API_URL}/close-batch-record?batch_id=${data.batch_id}`)
          .then((res) => {
            this.state.posting_wo = false;
            if (res.data.success) {
              resolve();
            } else {
              reject("Network Error");
            }
          })
          .catch((error) => {
            alert(JSON.stringify(error) + " CONTACT IT");
            reject("Network Error");
          });
      });
    },
  },
  getters: {
    active_wo_data: (state) => {
      // map data
      return state.active_wo;
    },
    batch_workorder_data: (state) => {
      return state.batchedWorkOrders;
    },
    NOW: (state) => {
      return state.NOWTime;
    },
  },
  modules: {},
});
