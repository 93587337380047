import Vue from "vue";
import VueRouter from "vue-router";
import login from "../views/login.vue";
import Dashboard from "../views/Dashboard.vue";
Vue.use(VueRouter);

import store from "../store/index.js";
const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (localStorage.badge_id) next();
      else next("/login");
    },
  },
  {
    path: "/login",
    name: "Login",
    component: login,
    beforeEnter: (to, from, next) => {
      if (localStorage.badge_id) {
        next("/");
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
