<template>
  <div>
    <div v-if="batch_started && !batch_complete" style="color: red; margin: auto; margin-top:20px; text-align: center;">
      <strong> DO NOT RE-LOAD THIS PAGE </strong>
    </div>
    <!-- <v-progress-linear  color="#1e60ae" height="10" value="10" striped></v-progress-linear> -->
    <v-progress-linear style="max-width:95%; margin: auto; margin-top:20px;" color="#1e60ae" :value="percent" height="25" striped v-if="batch_started">
      <!-- v-model="knowledge" -->
      <strong>{{ percent }}%</strong>
    </v-progress-linear>

    <br />
    <v-simple-table class="number_headers" v-if="!noOrdersToStart">
      <template v-slot:default>
        <tbody>
          <tr>
            <td>Status:</td>
            <td>
              <span v-if="!batch_started">-</span>
              <span v-if="batch_started && !batch_complete" style="color: #DEC20B!important;">WORKING</span>
              <!-- <span v-else-if="batch_complete">{{ queued_wo }}</span> -->
              <span v-if="batch_complete && !batch_err" style="color: green!important;"> COMPLETE </span>
              <span v-if="batch_err" style="color: crimson;"> ISSUE </span>
            </td>
          </tr>
          <tr>
            <td>Batch Type:</td>
            <td>{{ b_func | getBusType }}</td>
          </tr>
          <tr>
            <td>Work Time:</td>
            <td>{{ elapsedTime | changeToTime }}</td>
          </tr>
          <tr v-if="err_count != 0" style="background-color: #edc8c8;">
            <td>QC Holds:</td>
            <td>{{ err_count }}</td>
          </tr>
          <!-- <tr>
            <td>Operators:</td>
            <td>{{ operators_logged_in }}</td>
          </tr> -->
        </tbody>
      </template>
    </v-simple-table>
    <div style="text-align:center;" v-if="!batch_started && !noOrdersToStart && !batch_complete">
      <button class="bottom-button" @click="confirmBatch = true" v-if="batch_start_time == null" style="background: green!important; border: none;">Start</button>
      <button class="bottom-button" @click="confirmBatch = true" v-else style="background: #F28C28!important; border: none;">End</button>
    </div>
    <div v-if="batch_err" style="text-align:center;">
      <h2 class="text-h5" style="color: red; font-weight: 900;">
        {{ batch_err }}
      </h2>
    </div>
    <v-data-table :loading="spinner" loading-text="Loading... Please wait" v-if="!noOrdersToStart && !batch_complete" :headers="headers" :items="batch_workorder_todo" hide-default-footer disable-pagination class="elevation-1">
      <template v-slot:body="{ items }">
        <tbody>
          <!-- :class="{ inProcess: item.created.trim() }" -->
          <tr v-for="item in items" :key="item.tranid">
            <td>
              <!-- <span v-if="item.custbody_rush">
                <v-chip color="red" style="">
                  {{ item.tranid }}
                </v-chip>
              </span> -->
              <span>
                {{ item.tranid }}
              </span>
            </td>
            <td>{{ item.custcol_prp_blanket_po_item }}</td>
            <td>{{ item.custbody_date_released }}</td>
            <td>{{ item.formulatext | getTime }}</td>
            <td>{{ item.formulanumeric }}</td>
            <td>
              <span v-if="item.batch_status === 'q'">
                <!-- queued -->
                <v-icon style="-webkit-text-stroke: 0.5px black;">mdi-progress-clock</v-icon>
              </span>
              <span v-if="item.batch_status === 'w'">
                <!-- working -->
                <v-icon style="-webkit-text-stroke: 0.5px dark-blue;" class="icn-spinner">mdi-loading</v-icon>
              </span>
              <span v-if="item.batch_status === 's'">
                <!-- success -->
                <v-icon style="-webkit-text-stroke: 0.5px green;">mdi-checkbox-marked-circle-outline</v-icon>
              </span>
              <span v-if="item.batch_status === 'f'">
                <!-- failed -->
                <v-icon style="-webkit-text-stroke: 0.5px crimson;">mdi-alert-circle-outline</v-icon>
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <div v-if="noOrdersToStart" style="margin-top: 10px;">
      <h2 style="color: crimson; text-align: center;">
        No Work Orders to Start
      </h2>
    </div>
    <v-dialog v-model="confirmBatch" persistent max-width="900" height="300">
      <v-card v-if="!batch_spinner">
        <v-card-text style="text-align:center;">
          <h2 class="text-h5" style="padding-top: 20px; color: black; ">
            Are you sure you want to start this process?
            <span style="color: red;">
              <br />
              This cannot be un-done
            </span>
          </h2>
        </v-card-text>

        <v-row class="justify-space-between" style="margin:auto; width: 90%;">
          <v-col>
            <button class="submitBtn" style="background-color:crimson; border:none;" @click="confirmBatch = false">
              No
            </button>
          </v-col>
          <v-col>
            <button class="submitBtn" @click="startBatch()" v-if="batch_start_time == null">Yes</button>
            <button class="submitBtn" @click="endBatch()" v-else>Yes</button>
          </v-col>
        </v-row>
      </v-card>
      <div v-if="batch_spinner"></div>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    headers: [
      {
        text: "WorkOrder",
        align: "start",
        value: "tranid",
      },
      {
        text: "Item",
        align: "start",
        value: "custcol_prp_blanket_po_item",
      },
      {
        text: "Released",
        align: "start",
        value: "custbody_date_released",
      },
      {
        text: "ShipDate",
        align: "start",
        value: "formulatext",
      },
      {
        text: "Remaining",
        align: "start",
        value: "formulanumeric",
      },
      {
        text: "Status",
        align: "start",
        value: "batch_status",
      },
    ],
    batch_started: false,
    wo_to_complete: 0,
    noOrdersToStart: false,
    confirmBatch: false,
    queued_wo: 0,
    batch_workorder_todo: null,
    batch_spinner: false,
    percent: 0,
    batch_complete: false,
    err_count: 0,
    spinner: false,
    batch_err: null,
    elapsedTime: 0,
    // operators_logged_in: 1,
  }),
  filters: {
    getBusType: function(value) {
      switch (value) {
        case 6:
          return "Inner Linear";
        case 16:
          return "Seat-Covers";
        case 17:
          return "Bags & Accessories";
        case 25:
          return "Rush";
      }
    },
    getTime: function(value) {
      if (!value) return "-";
      else {
        var date = new Date(value);
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        return `${month}/${day}/${year}`;
      }
    },
    changeToTime(ms) {
      if (ms == null || ms == 0) {
        return "-";
      }
      // 1- Convert to seconds:
      ms = Math.round(ms);
      var seconds = Math.round(ms / 1000);
      // 2- Extract hours:
      var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
      seconds = seconds % 3600; // seconds remaining after extracting hours
      // 3- Extract minutes:
      var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
      // 4- Keep only seconds not extracted to minutes:
      seconds = seconds % 60;
      hours = ("0" + hours).slice(-2);
      minutes = ("0" + minutes).slice(-2);
      seconds = ("0" + seconds).slice(-2);
      if (Number(hours) === 0) return `${minutes}:${seconds}`;
      else return `${hours}:${minutes}:${seconds}`;
    },
  },
  props: ["entry_time", "b_func", "batch_type", "batch_start_time", "batch_id"],
  computed: {
    ...mapGetters(["batch_workorder_data", "NOW"]),
  },
  created() {
    this.queued_wo = this.batch_workorder_data.length;
    if (this.batch_workorder_data.length == 0) {
      this.noOrdersToStart = true;
    }
    this.batch_workorder_todo = JSON.parse(JSON.stringify(this.batch_workorder_data));
    this.batch_workorder_todo.forEach((e) => {
      e.batch_status = "q";
    });
  },
  mounted() {
    // console.log(this.batch_start_time);
    if (this.batch_start_time) {
      this.elapsedTime = new Date(this.NOW).getTime() - new Date(this.batch_start_time).getTime();
      this.timer = setInterval(() => {
        this.elapsedTime += 1000;
      }, 1000);
    }
  },
  methods: {
    async endBatch() {
      // this.spinner = true;
      const reloadData = () => {
        // this.$nextTick(() => {
        var _t = JSON.parse(JSON.stringify(this.batch_workorder_todo));
        this.batch_workorder_todo = [];
        this.batch_workorder_todo = _t;
        // });
      };
      this.confirmBatch = false;
      this.batch_started = true;
      this.$emit("wo_batch_func");
      var _td = JSON.parse(JSON.stringify(this.batch_workorder_todo));
      try {
        let result = _td.reduce((el, il, index) => {
          return el.then(async () => {
            this.batch_workorder_todo[index].batch_status = "w";
            reloadData();
            try {
              var ret_obj = {
                wc_login: localStorage.login_id,
                next_travel_state: "5",
                id: this.batch_workorder_todo[index].mopid,
                quantity_completed: this.batch_workorder_todo[index].formulanumeric,
              };
              var work_order_res = await this.$store.dispatch("endBatchedWorkOrder", ret_obj);
              if (work_order_res) {
                this.batch_workorder_todo[index].batch_status = "s";
                reloadData();
                var _t = JSON.parse(JSON.stringify(this.batch_workorder_todo));
                var _left = _t.filter((el) => el.batch_status != "q");
                this.percent = Math.round((_left.length / _t.length) * 100);
                this.queued_wo = _t.length - _left.length;
                reloadData();
              } else {
                this.batch_workorder_todo[index].batch_status = "f";
                reloadData();
                var _t = JSON.parse(JSON.stringify(this.batch_workorder_todo));
                var _left = _t.filter((el) => el.batch_status != "q");
                this.percent = Math.round((_left.length / _t.length) * 100);
                this.queued_wo = _t.length - _left.length;
                reloadData();
              }
            } catch (err) {
              console.log(err);
              this.batch_complete = true;
              this.batch_err = "Issue with Batch. Please Contact IT.";
              this.$emit("wo_batch_func");
              this.batch_workorder_todo[index].batch_status = "f";
              reloadData();
              throw new Error();
            }
          }); //.catch(err => console.log(err));
        }, Promise.resolve());

        result.then((e) => {
          this.batch_complete = true;
          this.$emit("wo_batch_func");
          var _t = JSON.parse(JSON.stringify(this.batch_workorder_todo));
          var err = _t.filter((el) => el.batch_status == "f");
          this.err_count = err.length;
          this.batch_workorder_todo = [];
          this.batch_workorder_todo = err;
          var batch_id = this.batch_id;
          try {
            this.$store.dispatch("closeBatchRecord", { batch_id: batch_id });
          } catch (err) {
            console.log(err);
          }
          //Batch Complete
        });
      } catch (err) {
        console.log(err);
      }
    },
    async startBatch() {
      this.spinner = true;
      const reloadData = () => {
        // this.$nextTick(() => {
        var _t = JSON.parse(JSON.stringify(this.batch_workorder_todo));
        this.batch_workorder_todo = [];
        this.batch_workorder_todo = _t;
        // });
      };
      this.confirmBatch = false;
      this.batch_started = true;
      this.$emit("wo_batch_func");
      var batch_id;
      try {
        batch_id = await this.$store.dispatch("getBatchId", {
          entry_time: this.entry_time.toISOString(),
          bussines_function: this.b_func,
        });
        this.spinner = false;
      } catch (err) {
        return;
      }
      var _td = JSON.parse(JSON.stringify(this.batch_workorder_todo));
      try {
        let result = _td.reduce((el, il, index) => {
          return el.then(async () => {
            this.batch_workorder_todo[index].batch_status = "w";
            reloadData();
            try {
              var work_order_res = await this.$store.dispatch("startBatchedWorkOrder", {
                wo: this.batch_workorder_todo[index].tranid,
                batch_id: batch_id,
              });
              if (work_order_res) {
                this.batch_workorder_todo[index].batch_status = "s";
                reloadData();
                var _t = JSON.parse(JSON.stringify(this.batch_workorder_todo));
                var _left = _t.filter((el) => el.batch_status != "q");
                this.percent = Math.round((_left.length / _t.length) * 100);
                this.queued_wo = _t.length - _left.length;
                reloadData();
              } else {
                this.batch_workorder_todo[index].batch_status = "f";
                reloadData();
                var _t = JSON.parse(JSON.stringify(this.batch_workorder_todo));
                var _left = _t.filter((el) => el.batch_status != "q");
                this.percent = Math.round((_left.length / _t.length) * 100);
                this.queued_wo = _t.length - _left.length;
                reloadData();
              }
            } catch (err) {
              console.log(err);
              this.batch_complete = true;
              this.batch_err = "Issue with Batch. Please Contact IT.";
              this.$emit("wo_batch_func");
              this.batch_workorder_todo[index].batch_status = "f";
              reloadData();
              throw new Error();
            }

            // return _u;
          }); //.catch(err => console.log(err));
        }, Promise.resolve());

        result.then((e) => {
          this.batch_complete = true;
          this.$emit("wo_batch_func");
          var _t = JSON.parse(JSON.stringify(this.batch_workorder_todo));
          var err = _t.filter((el) => el.batch_status == "f");
          this.err_count = err.length;
          this.batch_workorder_todo = [];
          this.batch_workorder_todo = err;
          //Batch Complete
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
<style lang="scss">
.isRush {
  background-color: pink;
  font-weight: bold;
}
.inProcess {
  background-color: #c4e1ea;
}
tr > td {
  font-size: 20px !important;
  font-weight: bold;
}
.number_headers {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 10px;
  width: 300px;
  border: solid 1px whitesmoke;
  margin: auto;
  margin-bottom: 10px;
  // font-size: 15px;

  // tr > td {
  //   font-size: 15px!important;
  //   font-weight: bold;
  // }
}
.icn-spinner {
  animation: spin-animation 1s infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
</style>
