<template>
  <div>
    <v-simple-table class="number_headers">
      <template v-slot:default>
        <tbody>
          <tr>
            <td>In Process:</td>
            <td>{{ in_process }}</td>
          </tr>
          <tr>
            <td>Ready:</td>
            <td>{{ queued_wo }}</td>
          </tr>
          <!-- <tr>
            <td>Operators:</td>
            <td>{{ operators_logged_in }}</td>
          </tr> -->
        </tbody>
      </template>
    </v-simple-table>

    <v-data-table :headers="headers" :items="active_wo_data" hide-default-footer disable-pagination class="elevation-1">
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.tranid" :class="{ inProcess: item.created.trim() }">
            <td>
              <span v-if="item.custbody_rush">
                <v-chip color="red" style="">
                  {{ item.tranid }}
                </v-chip>
              </span>
              <span v-else>
                {{ item.tranid }}
              </span>
            </td>
            <td>{{ item.custcol_prp_blanket_po_item }}</td>
            <td>{{ item.custbody_date_released }}</td>
            <td>{{ item.formulatext | geTime }}</td>
            <td>{{ item.formulanumeric }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    headers: [
      {
        text: "WorkOrder",
        align: "start",
        value: "tranid",
      },
      {
        text: "Item",
        align: "start",
        value: "custcol_prp_blanket_po_item",
      },
      {
        text: "Released",
        align: "start",
        value: "custbody_date_released",
      },
      {
        text: "ShipDate",
        align: "start",
        value: "formulatext",
      },
      {
        text: "Remaining",
        align: "start",
        value: "formulanumeric",
      },
      // {
      //   text: "Rush",
      //   align: "start",
      //   value: "custbody_rush",
      // },
    ],
    queued_wo: 0,
    in_process: 0,
    operators_logged_in: 1,
  }),
  filters: {
    geTime: function(value) {
      if (!value) return "-";
      else {
        var date = new Date(value);
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        return `${month}/${day}/${year}`;
      }
    },
  },
  computed: {
    ...mapGetters(["active_wo_data"]),
  },
  mounted() {
    this.active_wo_data.forEach((e) => {
      if (e.created.trim()) {
        console.log(e.created);
        this.in_process++;
      }
      this.queued_wo = this.active_wo_data.length - this.in_process;
    });
  },
  methods: {
    getColor(calories) {
      if (calories > 400) return "red";
      else if (calories > 200) return "orange";
      else return "green";
    },
  },
};
</script>
<style lang="scss">
.isRush {
  background-color: pink;
  font-weight: bold;
}
.inProcess {
  background-color: #c4e1ea;
}
tr > td {
  font-size: 20px !important;
  font-weight: bold;
}
.number_headers {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: 10px;
  width: 250px;
  border: solid 1px whitesmoke;
  margin: auto;
  margin-bottom: 10px

  // tr > td {
  //   font-size: 20px!important;
  //   font-weight: bold;
  // }
}
</style>
