<template>
  <v-app>
    <router-view />
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          App is Offline
        </v-card-title>
        <v-card-text>Tablet does not have wifi connected.</v-card-text>
      </v-card>
    </v-dialog>
    <!-- <div class="footer">{{ version }}</div> -->
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["posting_wo"]),
  },
  mounted() {
    localStorage.setItem("lastreload", Date.now() + 14400000); //600000
    setInterval(() => this.alertOffline(), 10000);

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  },
  data: () => ({
    dialog: false,
    version: process.env.VUE_APP_VERSION,
  }),
  methods: {
    alertOffline() {
      if (!navigator.onLine) {
        this.dialog = true;
      } else {
        this.dialog = false;
        if (localStorage.lastreload < Date.now()) {
          if (this.posting_wo) {
            localStorage.setItem("lastreload", Date.now() + 14400000); //600000
          } else {
            location.reload();
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
.application--wrap {
  // min-height: 100%!important;
  height: 100vh !important;
  /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100) !important;
  min-height: 0 !important;
}
.footer {
  position: fixed;
  bottom: 5px;
  left: 5px;
  color: crimson;
}
</style>
